import axios from "axios";
import { LOGIN_MESSAGES } from "../../components/helpers/UserFacingMessages";
import { signInWithGoogle } from "../../services/firebase";
import { types } from "../types/types";
import { showToast } from "./ui";

export const authLogin = () => {

  const { ERROR_DESCRIPTION } = LOGIN_MESSAGES;
  return async (dispatch) => {
    try {
      const resp = await signInWithGoogle();
      console.log(resp);
      if (resp) {
        const response = await isValidLogin(resp.user.uid, resp.user.email);
        if (!response.ok) {
          dispatch(showToast({
            variant: 'Danger',
            label: resp.msg,
          }));
        }
        localStorage.setItem('token', response.token);
        localStorage.setItem('user', JSON.stringify(resp.user));
        dispatch(startLogin(resp.user, response.token));
        dispatch(showToast({
          variant: 'Success',
          label: resp.msg,
        }));
      } else {
        dispatch(logout())
        dispatch(showToast({
          variant: 'Danger',
          label: ERROR_DESCRIPTION,
        }));
      }
    } catch (error) {
      dispatch(showToast({
        variant: 'Danger',
        label: ERROR_DESCRIPTION,
      }));
    }
  }
};

export const isValidLogin = async (uid, email) => {
  console.log(email)
  const resp = await axios.post(process.env.REACT_APP_API_URL + `/auth`, {
  email,
    uid,
  });
  return resp.data;
};

export const startLogin = (user, token) => ({
  type: types.AUTH_LOGIN,
  payload: { user, token },
});

export const logout = () => ({
  type: types.AUTH_LOGOUT,
});