import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import SubNavigation from '../../components/SubNavigation';

function LoanRecord() {

}

function Loans() {
    return (<>
            <SubNavigation data={[
            { 'Trading': '/trading' },
            { 'Lending': '/lending' },
            { 'Opportunities': '/opportunities' },
        ]}></SubNavigation>
        <Container className="text-start">
        <br />
            <Row>
                <Col md={8}>
                        <Stack direction="horizontal" gap={3}>
                            <div><div className="h2">Loans</div></div>
                            <div className="ms-auto"></div>
                            <div><Link to={`/loan/create`}>Track a Loan</Link></div>
                        </Stack>
                        <br />
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
    </>)
}

export default Loans