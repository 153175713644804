import React, { useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import { useDispatch, useSelector } from 'react-redux';

import { getBlacklistedNfts, unblacklistNft } from '../../redux/actions/nft';
import News from '../../components/news/News';

const Blacklist = () => {
  const dispatch = useDispatch();
  const { blacklist } = useSelector(state => state.nft);

  useEffect(() => {
    dispatch(getBlacklistedNfts());
  }, [dispatch]);

  const handleunBlacklist = (nft) => {
    dispatch(unblacklistNft(nft.hexId))
  };

  return (
    <Container>
      <br />
      <Row className="text-start">
        <Col md={8}>
          <Row>
            <Col>
              <div className="h2">Nft Blacklist</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={5}>
              <div className="h6">Total</div>
              <div className="h2">{blacklist?.length}</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="h2">NFTs</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {blacklist ?
                <Table className="summary">
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Description</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      blacklist.map((nft, index) => (
                        <tr key={`nft-value${index}`}>
                          <td>{nft.title || "N/A"}</td>
                          <td>{nft.description || "N/A"}</td>
                          <td>
                            <i className="mt-2 ms-2 bi bi-x-lg pointer" title="Remove from Blacklist" onClick={() => handleunBlacklist(nft)}></i>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : <p>No NFTS found</p>
              }
            </Col>
          </Row>
        </Col>
        <Col>
          <News />
        </Col>
      </Row>
    </Container>
  )
}

export default Blacklist;
