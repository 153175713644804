import { types } from "../types/types";

const initialState = {
  funds: null,
  selectedFund: null
};

export const fundsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FUNDS_SAVE_FUNDS:
      return {
        ...state,
        funds: action.payload,
      };
    case types.FUNDS_SAVE_FUND:
      return {
        ...state,
        selectedFund: action.payload,
      };
    case types.FUNDS_SAVE_DEFAULT_FUND:
      return {
        ...state,
        selectedFund: state.funds[0],
      };
    default:
      return state;
  }
};
