import axios from 'axios';
import { types } from '../types/types';
import { showToast } from "./ui";

const token = localStorage.getItem('token') || "";

export const createLoan = (loan) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_LOANS_API_URL}/loans/summary`, loan, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        }
      });
      if (result.status === 200) {
        dispatch(createdLoan(result.data));
      } else {
        /** 500 err */
      }
    } catch (error) {
      /* dispatch(showToast({
        variant: 'Danger',
        label: 'Please, try again!',
      })); */
    }
  };
};

export const getLoans = () => {
  return async (dispatch) => {
    try {
      const loans = await axios.get(process.env.REACT_APP_API_URL + `/loans/summary`,  {
        headers: {
          'x-token': token,
        }
      });
      
      if (loans.status === 200) {
        dispatch(saveLoans(loans.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const createdLoan = (loan) => ({
  type: types.LOAN_CREATED_LOAN,
  payload: loan,
});

const saveLoans = (loans) => ({
  type: types.LOANS_SAVE_LOANS,
  payload: loans,
});