import { types } from "../types/types";

const initialState = {
  loans: null,
  selectedFund: null
};

export const loansReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAN_CREATED_LOAN:
      return {
        ...state,
        loan: action.payload,
      };
    case types.LOANS_SAVE_LOANS:
      return {
        ...state,
        loans: action.payload,
      };
    default:
      return state;
  }
};