import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
function SubNavigation(props) {
    let navitems = props.data.map((item, index) => {
        let navItemName = Object.keys(item)[0];
        let navItemValue = item[Object.keys(item)[0]];
        return <Nav.Link href={navItemValue} key={index}>{navItemName}</Nav.Link>
    })
    return (
        <Navbar bg="dark" className="secondary-nav" expand="lg">
            <Container>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        {navitems}
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default SubNavigation;