import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Button, Col, Row, Table } from 'react-bootstrap';
import { pubnub } from '../../../../services/pubnub';
import { dateFromNow } from '../../utils';
const token = localStorage.getItem('token') || "";

async function fetchListings(owners) {
  try {
    const result = await axios.get(process.env.REACT_APP_API_URL + `/listings/${owners}`)
    return result;
  } catch (error) {
    return error;
  }
};

const Opportunities = ({ uuid, collections }) => {
  const [opportunities, setOpportunities] = useState([]);


  const pubnubConnection = pubnub(uuid);

  useEffect(() => {
    const getOpportunities = async () => {
      try {
        const response = await Promise.all(collections.map(collection => fetchListings(collection.contract_address)));
        setOpportunities(...response.filter(resp => resp.data?.listing).map(response => response.data.listing))

      } catch (error) {
        console.log(error)
      }
    }
    if (collections.length > 0) {
      getOpportunities();
    }
  }, [collections, setOpportunities]);

  useEffect(() => {
    const showMessage = (newOpportunity) => {
      let _limitOpportunities = opportunities;
      console.log({ newOpportunity })
        if(_limitOpportunities.length > 20) {
          _limitOpportunities.pop()
        }
      let _mergedOpportunities = [newOpportunity, ...opportunities];
      setOpportunities(_mergedOpportunities);
    };

    // add listener
    const listener = {
      status: (statusEvent) => {
        if (statusEvent.category === "PNConnectedCategory") {
          console.log("Connected")
        }
      },
      message: (messageEvent) => {
        showMessage(messageEvent.message.description);
      },
      presence: (presenceEvent) => {
        // handle presence
        console.log(presenceEvent);

      }
    };
    pubnubConnection.addListener(listener);
    // cleanup listener
    return () => {
      pubnubConnection.removeListener(listener)
    }
  }, [pubnubConnection]);

  useEffect(() => {
    // subscribe to a channel
    collections.length > 0 && pubnubConnection.subscribe({
      channels: collections.map(collection => collection.opensea_slug)
    });
    // cleanup subscription
    return () => {
      pubnubConnection.unsubscribe({
        channels: collections.map(collection => collection.opensea_slug)
      });
    }
  }, [pubnubConnection, collections]);

  return (
    <Row>
      <Col>
        <div className="h3">Opportunities</div>
        <Table>
          <thead>
            <tr>
              <th>Image</th>
              <th>Asset</th>
              <th>Wallet</th>
              <th>Listed $</th>
              <th>Valuation $</th>
              <th>Potential ROI</th>
              <th>Event Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {
              opportunities?.map((opportunity, index) => {
                const roi = (!isNaN(opportunity.valuation)) ? (parseFloat(opportunity.valuation) - parseFloat(opportunity.base_price) / Math.pow(10, 18)).toFixed(4) + " " + opportunity.payment_token.symbol : 'N/A';
                return (<tr key={`opportunity-${index}`}>

                  <td className="align-middle"><img alt="asset" className="asset rounded float-start" src={opportunity.item?.metadata?.image_url} /></td>
                  <td className="align-middle"><a href={`https://opensea.io/assets/${opportunity.item.nft_id}`} target="_blank" rel="noreferrer">{opportunity.item.nft_id.split('/')[2]}</a> </td>
                  <td className="align-middle">...</td>
                  <td className="align-middle">{opportunity.base_price / Math.pow(10, 18)} {opportunity.payment_token?.symbol}</td>
                  <td className="align-middle">{(!isNaN(opportunity?.valuation)) ? parseFloat(opportunity.valuation).toFixed(3) + " " + opportunity.payment_token.symbol : "N/A"}</td>
                  <td className="align-middle">{roi}</td>
                  <td className="align-middle">{dateFromNow(opportunity.event_timestamp)}</td>
                  <td className="align-middle"><Button variant="primary" size="sm"><i className="bi bi-cart-plus"></i></Button></td>
                </tr>)
              })
            }
          </tbody>
        </Table>
      </Col>
    </Row>
  )
}

export default Opportunities;
