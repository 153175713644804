import axios from 'axios';
import { types } from '../types/types';

const token = localStorage.getItem('token') || "";

export const getFunds = () => {
  return async (dispatch) => {
    try {
      const funds = await axios.get(process.env.REACT_APP_API_URL + `/funds`, {
        headers: {
          'x-token': token,
        }
      });
      if (funds.data.ok) {
        dispatch(saveFunds(funds.data.fund));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const selectFund = (fund) => {
  return (dispatch) => {
    dispatch(saveFund(fund));
  };
};

export const defaultFund = () => {
  return (dispatch) => {
    dispatch(saveDefaultFund());
  }
}

const saveFunds = (funds) => ({
  type: types.FUNDS_SAVE_FUNDS,
  payload: funds,
});

const saveFund = (fund) => ({
  type: types.FUNDS_SAVE_FUND,
  payload: fund,
});

const saveDefaultFund = () => ({
  type: types.FUNDS_SAVE_DEFAULT_FUND,
})