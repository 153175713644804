import moment from "moment";

export const isArrayWithLength = (array) => {
  return array && array.length > 0;
};

export const isArrayWithLengthAndValues = (array) => {
  return array && array.length > 0 && array.every(item => item);
};

export const fixDecimals = (value, decimals = 2) => {
  return value ? value.toFixed(decimals) : 0;
};

export const timeSinceLastUpdate = (date) => {
  return new Date(date).toLocaleString();
};

export const dateFromNow = (date) => {
  return moment(date).fromNow();
}