import { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { NavDropdown } from 'react-bootstrap';
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { logout, startLogin } from '../redux/actions/auth';
import { defaultFund, getFunds, selectFund } from '../redux/actions/fund';
import { getBlacklistedNfts } from '../redux/actions/nft';
import { getAccountsByFund } from '../redux/actions/account';


function Navigation() {
  const dispatch = useDispatch();
  const { auth, fund } = useSelector(state => state);
  const { user, loggedIn } = auth;
  const { selectedFund, funds } = fund;

  const [displayFund, setDisplayFund] = useState(selectedFund?.name);

  useEffect(() => {
    const token = localStorage.getItem('token') || "";
    if (token) {
      const user = localStorage.getItem('user');
      dispatch(startLogin(JSON.parse(user)));
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchFunds = () => {
      dispatch(getFunds());
    }
    fetchFunds();
  }, [dispatch]);

  useEffect(() => {
    if (funds?.length > 0) {
      dispatch(defaultFund());
    }
  }, [dispatch, funds]);

  useEffect(() => {
    setDisplayFund(selectedFund?.name);
  }, [selectedFund]);

  useEffect(() => {
    const fetchAccounts = () => {
      if (!selectedFund) {
        return;
      }
      dispatch(getAccountsByFund(selectedFund._id));
    }
    fetchAccounts();
  }, [dispatch, selectedFund]);

  useEffect(() => {
    dispatch(getBlacklistedNfts());
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout());
  };

  const handleSelectFund = (e) => {
    const newFund = funds.find((fund) => fund._id === e);
    dispatch(selectFund(newFund));
  };

  const renderUserLogoutDropdown = () => {
    return (
      <NavDropdown title={user.displayName.split(" ")[0]} id="nav-dropdown" variant="secondary">
        <NavDropdown.Item eventKey="4.2" as={NavLink} to="/nft-blacklist">Blacklist</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4" onClick={handleLogout}>Log Out</NavDropdown.Item>
      </NavDropdown>
    );
  };

  const LoginButton = () => {
    if (loggedIn) {
      // return <Button variant="secondary" onClick={handleLogout}>Log Out</Button>
      return (renderUserLogoutDropdown());
    } else {
      return <Button variant="primary" as={NavLink} to="/login">Log In</Button>
    }
  };

  return (
    <Navbar className={`${loggedIn ? "" : "d-none"}`} collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="/home">
          <img
            alt="M4-logo"
            src="/logo-small.svg"
            width="60"
            height="20"
            className="d-inline-block align-top"
          />
        </Navbar.Brand>
        <Nav className="me-auto my-2 my-lg-0">
          <Dropdown onSelect={e => handleSelectFund(e)}>
            <Dropdown.Toggle variant="success" id="dropdown-basic" value={displayFund}>
              {displayFund}
            </Dropdown.Toggle>
            <Dropdown.Menu >
              {funds?.map((fund) => (
                <Dropdown.Item
                  key={`fund-${fund._id}`}
                  eventKey={fund._id}
                >
                  {fund.name}
                </Dropdown.Item>
              ))}

            </Dropdown.Menu>
          </Dropdown>
        </Nav>
        <Nav >

          <Nav.Item>
            <Nav.Link as={NavLink} to="/summary" eventKey="link-1">Summary</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/collection" eventKey="link-2">Collections</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/loan" eventKey="link-3">Loans</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/valuation" eventKey="link-4">Valuations</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link as={NavLink} to="/account" eventKey="link-5">Accounts</Nav.Link>
          </Nav.Item>
          <Form>
            <LoginButton {...{ user: user, clickFn: handleLogout }} />
          </Form>
        </Nav>

      </Container>
    </Navbar >
  );
}

export default Navigation;
