import { types } from "../types/types";

export const showToast = (toast) => ({
  type: types.UI_OPEN_TOAST,
  payload: toast,
});

export const closeToast = () => ({
  type: types.UI_CLOSE_TOAST,
});
