import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
    const { auth } = useSelector(state => state);
    const { loggedIn } = auth;

    return loggedIn ? <Outlet /> : <Navigate to="/login" />
};

export default PrivateRoute;
