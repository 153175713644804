import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropdownButton } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate } from "react-router-dom";
import { createAccount } from "../../redux/actions/account";

function AccountCreate() {

	const dispatch = useDispatch();
	const { funds, selectedFund } = useSelector(state => state.fund);
	const navigate = useNavigate();

	const [account, setAccount] = useState({});
	const [selectedFundOption, setSelectedFundOption] = useState("");
	const [selectedFundName, setSelectedFundName] = useState(selectedFund.name);

	useEffect(() => {
		setSelectedFundName(selectedFund.name);
	}, [selectedFund]);

	const handleSubmit = (e) => {
		e.preventDefault();
		e.target.reset();
		dispatch(createAccount({
			...account,
			fund: selectedFundOption,
		}));
		setAccount({});
		navigate('/account', { replace: true });
	};

	const changeFund = (e) => {
		setSelectedFundOption(e);
		setSelectedFundName(funds.find(fund => fund._id === e).name);
	};

	return (
		<Container className="text-start">
			<br />
			<Row>
				<Col>
					<div className="h2">Track an Account</div>
					<br />
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Name</Form.Label>
							<Form.Control type="text"
								name="account[name]"
								value={account.name}
								onChange={e => setAccount({ ...account, name: e.target.value })}
								placeholder="Account Name" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Address</Form.Label>
							<Form.Control type="text"
								name="account[address]"
								value={account.address}
								onChange={e => setAccount({ ...account, address: e.target.value })}
								placeholder="Account Address" />
						</Form.Group>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Assigned To</Form.Label>
							<DropdownButton onSelect={e => changeFund(e)} id="dropdown-basic-button" title={selectedFundName || "Select a Fund"}>
								{funds?.map((fund, index) => (
									<Dropdown.Item key={`fund-${index}`} eventKey={fund._id}>{fund.name}</Dropdown.Item>
								))}
							</DropdownButton>
						</Form.Group>
						<Button variant="primary" type="submit" disabled={!account.name || !account.address || !selectedFundOption}>
							Submit
						</Button>
					</Form>
				</Col></Row></Container>
	);
}

export default AccountCreate;
