import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Table from 'react-bootstrap/Table';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import LineChart from '../../components/LineChart';
import Filter from '../../components/Filter';
import News from '../../components/news/News';
import SubNavigation from '../../components/SubNavigation';
import { dateFromNow, fixDecimals } from '../../components/utils/utils';
import Opportunities from '../../components/utils/Common/Opportunities/Opportunities';
import { fetchAnalytics, fetchCollection, fetchOlhc, fetchTrades } from '../../redux/actions/collection';

function transformData(originalData, interval) {
	const data = originalData.reverse();

	let ohlcData = data.map((data, index) => {
		let theTime = moment(data._id.date?.split('T')[0]).unix()
		if (interval === 'hour') {
			theTime = moment(data._id.date).unix();
		}

		let tmpData = {
			'time': theTime,
			'open': parseFloat(data.open_price),
			'high': parseFloat(data.max_price),
			'low': parseFloat(data.min_price),
			'close': parseFloat(data.close_price)
		}
		return tmpData;
	})

	let volData = data.map((data, index) => {
		let theTime = moment(data._id.date?.split('T')[0]).unix()
		if (interval === 'hour') {
			theTime = moment(data._id.date).unix();
		}
		let tmpData = {
			'time': theTime,
			'value': parseFloat(data.volume),
		}
		return tmpData;
	})

	// get origina data
	// get start and end date
	// iterate from start and end date
	// if date exists, do nothing, if date does not exist, push data to object
	// sort after all is done
	return { ohlc: ohlcData, vol: volData, interval };
}

function TradeBlock({ data }) {
	let symbol = ((data?.payment_token?.symbol === null) ? ' ETH' : data?.payment_token?.symbol);
	let image_url = data?.asset?.image_url === null ? (<div className="placeholder asset rounded d-flex align-items-center justify-content-center"><i className="bi bi-card-image"></i></div>) : (<img alt="asset" className="asset rounded float-start" src={data?.asset?.image_url} />)
	return (<>
		<tr>
			<td className="align-middle">{image_url}</td>
			<td className="align-middle">{data?.platform === 'looksrare' ?
				<a href={`https://looksrare.org/collections/` + data?.asset?.contract_address + '/' + data?.asset?.token_id} target="_blank" rel="noreferrer">#{data?.asset?.token_id}</a>
				:
				<a href={`https://opensea.io/assets/` + data?.asset?.contract_address + '/' + data?.asset?.token_id} target="_blank" rel="noreferrer">#{data?.asset?.token_id}</a>}</td>
			<td className="align-middle"><a href={`https://etherscan.io/tx/` + data?.transaction?.tx_hash} target="_blank" rel="noreferrer">{(parseFloat(data?.total_price) / Math.pow(10, 18)).toFixed(2) + ' ' + symbol}</a></td>
			<td className="align-middle">{(data?.valuation && data?.valuation?.meta4 !== null) ? data?.valuation?.meta4?.toFixed(2) + ' ETH' : 'N/A'}</td>
			<td className="align-middle"><img alt="collection" className="asset rounded" src={'/' + data?.platform + '-logo.png'} /></td>
			<td className="align-middle">{data?.buyer}</td>
			<td className="align-middle">{dateFromNow(data?.transaction_timestamp) + ` (${moment(data?.transaction_timestamp).format('YYYY-MM-DD hh:mm:ss')})`}</td>
		</tr>
	</>);
}

function CollectionDetails() {

	const dispatch = useDispatch();
	const { collection, analytics, owner, olhc, trades, increment } = useSelector(state => state.collection);
	const { accounts } = useSelector(state => state.account);

	// get id from route
	const [fixedOlhc, setOlhc] = useState([]);
	const [nfts, setNfts] = useState([]);

	const [minDate, setMinDate] = useState(moment().subtract(90, 'days').format('YYYY-MM-DD'));
	const [maxDate, setMaxDate] = useState(moment().format('YYYY-MM-DD'));
	const [interval, setInterval] = useState('day');

	const { data } = useParams();
	const [id, uuid] = data.split("|");

	useEffect(() => {

		dispatch(fetchOlhc(id, minDate, maxDate, interval))

		dispatch(fetchAnalytics(id));

		dispatch(fetchTrades(id, minDate, maxDate));

		dispatch(fetchCollection(id));

	}, [dispatch, id, increment, maxDate, minDate, interval]);

	useEffect(() => {
		if (olhc.length > 0) { setOlhc(transformData(olhc, interval)); }
	}, [olhc, interval]);

	useEffect(() => {
		const getNfts = () => {
			const fundNfts = accounts?.reduce((acc, account) => account.nfts ? [...acc, ...account.nfts.ownedNfts] : acc, [])
				.filter(nft => nft.contract.address === owner);
			setNfts(fundNfts);
		};

		if (owner) {
			getNfts();
		}
	}, [owner, accounts]);

	return (
		<>
			<SubNavigation data={[
				{ 'Trading': '/trading' },
				{ 'Lending': '/lending' },
				{ 'Opportunities': '/opportunities' },
			]}></SubNavigation>
			<Container className="text-start">
				<br />
				<Row>
					<Col md={8}>
						<Filter
							name={collection?.collection_name}
							minDate={minDate}
							maxDate={maxDate}
							interval={interval}
							updateInterval={{
								setMinDate,
								setMaxDate,
								setInterval
							}} />
						<br />
						<Row>
							<Col>
								<div className="fs-6 underline">Current Floor</div>
								<div className="fs-2 text">{analytics[0]?.last_price?.toFixed(2) + ' ETH'}</div>
							</Col>
							<Col>
								<div className="fs-6 underline">Floor Change (24hr)</div>
								<div className="fs-2 text">{(analytics[0]?.floor_price_change * 100).toFixed(2) + ' %'}</div>
							</Col>
							<Col>
								<div className="fs-6 underline">Volume Change (24hr)</div>
								<div className="fs-2 text">{(analytics[0]?.volume_change * 100).toFixed(2) + ' %'}</div>
							</Col>
						</Row>
						<br />
						<LineChart props={fixedOlhc} />
						<br />
						{collection?.contract_address && (
							<Opportunities uuid={uuid} collections={[collection]} />
						)}
						<br />
						<Row>
							<Col>
								<div className="h3">Holdings</div>
								<Table>
									<thead>
										<tr>
											<th>Image</th>
											<th>Asset</th>
											<th>Wallet</th>
											<th>Purchase $</th>
											<th>Est. $</th>
											<th>ROI</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{
											nfts?.map((nft, index) => (
												<tr key={`nft-${index}`}>
													<td className="align-middle"><img alt="asset" className="asset rounded float-start" src={nft.media[0]?.gateway} /></td>
													<td className="align-middle">{nft.title || "N/A"}</td>
													<td className="align-middle">{nft.contractMetadata.name}</td>
													<td className="align-middle">N/A</td>
													<td className="align-middle">{`${fixDecimals(nft.price, 3)} ETH` || "00"} ({`${`$` + fixDecimals(nft.USD, 3)}` || "00"})</td>
													<td className="align-middle">+50%</td>
													<td className="align-middle"><Button variant="secondary" size="sm"><i className="bi bi-cash-stack"></i></Button></td>
												</tr>
											))
										}
									</tbody>
								</Table>
							</Col>
						</Row>
						<br />
						<Row>
							<Col>
								<div className="h3">Recent Sales</div>
								<Table>
									<thead>
										<tr>
											<th>Image</th>
											<th>Asset</th>
											<th>Sale Price (ETH)</th>
											<th>Est. Valuation (ETH)</th>
											<th>Platform</th>
											<th>Buyer Address</th>
											<th>Event Time</th>
										</tr>
									</thead>
									<tbody>
										{
											trades.map((trade, i) => {
												return <TradeBlock key={i} data={trade} />
											})
										}
									</tbody>
								</Table>
							</Col>
						</Row>
					</Col>
					<Col md={4}>
						<div className="h3">Details</div>
						<Row>
							<Col>
								<ListGroup as="ul">
									<ListGroup.Item
										as="li"
										className="d-flex justify-content-between align-items-start"
									>
										<div className="me-auto">
											<div className="fw-bold">Links</div>
											<a href={`https://opensea.io/collection/` + collection?.opensea_slug} target="_blank" rel="noreferrer">Goto Opensea</a>&nbsp;
											<Link to={`/valuation/` + collection?.contract_address}>Goto Valuation</Link>
										</div>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										className="d-flex justify-content-between align-items-start"
									>
										<div className="me-auto">
											<div className="fw-bold">Creator Fee</div>
											{collection?.dev_seller_fee_basis_points / 100}%
										</div>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										className="d-flex justify-content-between align-items-start"
									>
										<div className="me-auto">
											<div className="fw-bold">Platform Fee</div>
											2.5%
										</div>
									</ListGroup.Item>
									<ListGroup.Item
										as="li"
										className="d-flex justify-content-between align-items-start"
									>
										<div className="me-auto">
											<div className="fw-bold">Min. Profit (Est. Floor)</div>
											{(((collection?.dev_seller_fee_basis_points / 10000 + .025) + 1) * analytics[0]?.last_price).toFixed(2)} ETH
										</div>
									</ListGroup.Item>
								</ListGroup>
							</Col>
						</Row>
						<br />
						<News tags={collection?.tags} daysAgo={7} />
					</Col>
				</Row>
			</Container>
		</>
	);
}
export default CollectionDetails