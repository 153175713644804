import React from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Stack from 'react-bootstrap/Stack';
import { getCollections } from '../../redux/actions/collection';
import { createLoan } from '../../redux/actions/loan';
import SubNavigation from '../../components/SubNavigation';

function LoanCreate() {
    const dispatch = useDispatch();
	const { collections } = useSelector(state => state.collection);
    const { loan } = useSelector(state => state.loan);
    const [assets, setAssets] = useState([])
    const [formData, setFormData] = useState({})
    const [loanArr, setLoanArr] = useState([])
    const [forceUpdate, setForceUpdate] = useState(0)
    
    const fetchCollections = () => {
        dispatch(getCollections(0, 100));
    }

    const fetchLoanAssessment = (formData) => {
        dispatch(createLoan(formData));
    }

    useEffect(() => {
		fetchCollections();
	}, [dispatch]);

    function CollectionLookup(collectionData, contractAddress) {
        return collectionData.filter((collection, index)=>{
            return collection.contract_address.includes(contractAddress)
        });
    }

    function LoanSummary() {
        let _loanSummary = loan?.summary;
        let totalLoanValue = loan?.details.reduce(function (acc, obj) { 
            console.log(obj);
            return acc + obj.avg_strike; }, 0);
        let nftbankValue = loan?.details.reduce(function (acc, obj) { 
            console.log(obj);
            return acc + obj.nftbank_strike; }, 0);
        let meta4Value = loan?.details.reduce(function (acc, obj) { 
            console.log(obj);
            return acc + obj.meta4_strike; }, 0);
        
        return(<>
            <Row>
                <Col>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan="2">Loan Details</th>
                                </tr>
                            </thead>
                            <tbody>

                            <tr>
                                <td width="50%">Name</td>
                                <td>{formData.loanName}</td>
                            </tr>
                            <tr>
                                <td>Term</td>
                                <td>{formData.loanTerm}</td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{formData.loanStatus}</td>
                            </tr>
                            <tr>
                                <td>Avg. Strike (TVL)</td>
                                <td>{totalLoanValue?.toFixed(2)} ETH</td>
                            </tr>
                            <tr>
                                <td>Meta4 Strike (TVL)</td>
                                <td>{meta4Value?.toFixed(2)} ETH</td>
                            </tr>
                            <tr>
                                <td>NFTB Strike (TVL)</td>
                                <td>{nftbankValue?.toFixed(2)} ETH</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Table>
                            <thead>
                                <tr>
                                    <th colSpan="2">Valuation Metrics</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td width="50%">Meta4</td>
                                <td>{_loanSummary?.meta4_valuation.toFixed(2)} ETH</td>
                            </tr>
                            <tr>
                                <td>Nexo</td>
                                <td>{_loanSummary?.nexo_valuation.toFixed(2)} ETH</td>
                            </tr>
                            <tr>
                                <td>NFTBank</td>
                                <td>{_loanSummary?.nftbank_valuation.toFixed(2)} ETH</td>
                            </tr>
                            <tr>
                                <td>Average</td>
                                <td>{_loanSummary?.avg_valuation.toFixed(2)} ETH</td>
                            </tr>
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col md={{ span: 3, offset: 9  }}>
                            <div className="d-flex justify-content-end">
                                <Button variant="primary" type="submit">Submit</Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>)
    }

    function LoanHistory() {
        return(<>
            <Row>
                <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th colSpan="2">Loans</th>
                            </tr>
                        </thead>
                        <tbody>
                        {/* { 
                            _loanHistory.forEach((historyRecord, index)=>{
                            return (<tr key={index}>
                                    <td>{historyRecord.loanTerm}</td>
                                </tr>)
                            })    
                        } */}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>)
    }

    function CollectionSelect(props) {
        let index = props.index;
        return (<Form.Select defaultValue={assets[index].contract_address} name={`assets[${index}].contract_address`} onChange = {(e) => updateLoanRecord(e, index, 'contract_address') } ><option>Select an Option</option>   
            {
                collections?.map((collection, key)=>{
                    if(collection.overall_collection_name === undefined) {
                        return <option key={key} value={collection.contract_address}>{collection.collection_name}</option>
                    } else {
                        return <option key={key} value={collection.contract_address}>{collection.collection_name}</option>
                    }
                })
            }
        </Form.Select>)
    }

    function LoanRecord(props) {
        let index = props.index;
        let asset = props.asset;
        let _tmpAssets = assets;
        return(<>
            <tr key={index}>
                <td>
                    <CollectionSelect index={index}/>
                </td>
                <td>
                    <Form.Control 
                        name={`assets[${index}].token_id`} 
                        defaultValue={_tmpAssets[index].token_id} 
                        type="text" onChange = {(e) => updateLoanRecord(e, index, 'token_id') } placeholder="e.g. 1304" />
                </td>
                <td>
                    <Form.Control 
                        type="text" 
                        name={`assets[${index}].nexo_valuation`} 
                        defaultValue={_tmpAssets[index].nexo_valuation.toString()} onChange = {(e) => updateLoanRecord(e, index, 'nexo_valuation') }
                        placeholder="e.g. 90" />
                </td>
                <td>
                    <Form.Control 
                        type="text" 
                        name={`assets[${index}].ltv`} 
                        defaultValue={_tmpAssets[index].ltv.toString()} onChange = {(e) => updateLoanRecord(e, index, 'ltv') } 
                        placeholder="e.g. .4" />
                </td>
                <td>
                    <Row>
                        <Button onClick={(e) => {removeLoanRecord(e, index)} }><i className="bi bi-clipboard-minus"></i></Button>
                    </Row>
                </td>
            </tr>
        </>)
    }

    function LoanResultRecord(props) {
        let index = props.index;
        let asset = props.asset;
        let collection = CollectionLookup(collections, asset.contract_address)
        return (<tr>
            <td>{collection.length > 0 ? collection[0].collection_name : asset.contract_address}</td>
            <td>{asset.token_id}</td>
            <td>{asset.meta4_valuation} / {asset.nexo_valuation} / {asset.nftbank_valuation} ETH</td>
            <td>{asset.meta4_strike} / {asset.nexo_strike} / {asset.nftbank_strike} ETH</td>
            <td>{((asset.meta4_strike+asset.nexo_strike+asset.nftbank_strike)/3).toFixed(2)} ETH</td>
            <td>{asset.recent_floor} ETH</td>
            <td><Button><i className="bi bi-zoom-in"></i></Button></td>
        </tr>)
    }

    useEffect(()=>{
        // update state if force update is fired
        // if loans length is 0, initialize first element
        if(assets.length <= 0) {
            let updateAssets = assets;
            let _forceUpdate = forceUpdate;
            updateAssets.push({
                contract_address: "0x60e4d786628fea6478f785a6d7e704777c86a7c6",
                subcollection_name: "",
                token_id: "",
                source: "nexo",
                nexo_valuation: "",
                ltv: ""
            })
            setAssets(updateAssets)
            _forceUpdate++;
            setForceUpdate(_forceUpdate)
        }
    },[assets, forceUpdate])

    const updateLoanRecord = (e, index, name) => {
        let _tmpAssets = assets
        let _formData = formData
        _tmpAssets[index][name] = e.target.value
        _formData['assets'] =  _tmpAssets;
        setFormData(_formData);
        setAssets(_tmpAssets)
    }

    const updateLoanForm = (e, name) => {
        console.log(e.target.value, name);
        let _formData = formData
        _formData[name] = e.target.value;
        setFormData(_formData);
    }

    const submitFn = (e) => {
        e.preventDefault();
        let _forceUpdate = forceUpdate;
        let _tmpAssets = assets.filter((asset, index)=>{
            asset.ltv = parseFloat(asset.ltv);
            asset.nexo_valuation = parseFloat(asset.nexo_valuation);
            asset.source = formData.source;
            if(asset["contract_address"] !== undefined && asset?.contract_address.length === 0) {
                // delete asset.contract_address;
            } else if(asset["subcollection_name"] !== undefined && asset?.subcollection_name.length === 0) {
                delete asset.subcollection_name;
            }
            return (asset.contract_address.length > 0)
        });
        
        /** Submit Form */

        // set the assets being measured
        setAssets(_tmpAssets);

        // fetch assessment from api
        fetchLoanAssessment(JSON.stringify(_tmpAssets));
        let _tmpFormData = formData;
        _tmpFormData.assets = _tmpAssets;

        // set form data
        setFormData(_tmpFormData);

        //TODO: Set Loan History

        _forceUpdate++;
        setForceUpdate(_forceUpdate)
    }

    const addLoanRecord = (e) => {
        e.preventDefault();
        let updateAssets = assets;
        let _forceUpdate = forceUpdate;
        updateAssets.push({
            contract_address: "",
            subcollection_name: "",
            token_id: "",
            nexo_valuation: "",
            ltv: ""
        })
        setAssets(updateAssets)
        _forceUpdate++;
        setForceUpdate(_forceUpdate)
    }

    const removeLoanRecord = (e,key) => {
        //remove element from array
        let updateAssets = assets.splice(key, 1);
        let _forceUpdate = forceUpdate;
        _forceUpdate++;
        setForceUpdate(_forceUpdate)
    }

    return (<>
            <SubNavigation data={[
            { 'Trading': '/trading' },
            { 'Lending': '/lending' },
            { 'Opportunities': '/opportunities' },
        ]}></SubNavigation>
        <Container className="text-start">
        <br />
        <Form onSubmit={submitFn}>
            <Row>
                <Col md={8}>
                    <Stack direction="horizontal" gap={3}>
                        <div><div className="h2">Create a Loan</div></div>
                    </Stack>
                    <br />
                    
                        <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>Loan Name</Form.Label>
                                    <Form.Control name="name" type="text" defaultValue="" placeholder="Enter a loan name" onChange={(e) => updateLoanForm(e, "loanName")} />
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Loan Term</Form.Label>
                                    <Form.Select defaultValue={formData.loanTerm} name="loanTerm" onChange={(e) => updateLoanForm(e, "loanTerm")}>
                                    <option>Select an Option</option>    
                                    <option value="7">7</option>
                                    <option value="14">14</option>
                                    <option value="30">30</option>
                                    <option value="60">60</option>
                                    <option value="90">90</option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Originator</Form.Label>
                                    <Form.Select name="source" onChange={(e) => updateLoanForm(e, "source")}>
                                    <option>Select an Option</option>   
                                    <option value="nexo">Nexo</option>
                                    </Form.Select>
                                    <Form.Text className="text-muted">
                                        The party sourcing the loan.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                            <Col md={2}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Status</Form.Label>
                                    <Form.Select defaultValue={formData.loanStatus} name="loanStatus" onChange={(e) => updateLoanForm(e, "loanStatus")}>
                                    <option>Select an Option</option>       
                                    <option value="offered">Offered</option>
                                    <option value="pending">Pending</option>
                                    <option value="closed">Closed</option>
                                    </Form.Select>
                                    <Form.Text className="text-muted">
                                        The status of the loan.
                                    </Form.Text>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                    <Table>
                        <thead>
                            <tr>
                                <th>Collection</th>
                                <th>Token ID</th>
                                <th>Nexo Valuation</th>
                                <th>LTV</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody id="loan-record">
                            {
                                assets.map((asset, index) => {
                                   return <LoanRecord asset={asset} index={index} />
                                })
                            }
                        </tbody>
                    </Table>
                    </Col>
                    </Row>
                    <Row>
                        <Col md={{ span: 3, offset: 9 }} className="text-right"><div className="d-flex justify-content-end"><Button onClick={(e)=> {addLoanRecord(e)}}><i className="bi bi-clipboard-plus"></i></Button></div></Col>
                    </Row>
                    <hr/>
                    <Row>
                        <Col md={{ span: 3, offset: 9  }}><div className="d-flex justify-content-end">
                            <Button variant="primary"type="submit">
                                Analyze
                            </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <div className="h2">Details</div></Col>
                    </Row>
                    <Row>
                    <Col>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Collection</th>
                                    <th>Token ID</th>
                                    <th>Meta4 / Nexo / NFTB Val.</th>
                                    <th>Meta4 / Nexo / NFTB Strike</th>
                                    <th>Avg. Strike</th>
                                    <th>Recent Floor</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                loan?.details.map((assetResult, index) => {
                                    return <LoanResultRecord asset={assetResult} index={index} />
                                })
                            }
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                </Col>
                <Col md={4}>
                    <Row>
                        <Col className="h3" md={12}>Summary</Col>
                    </Row>
                    <Row>
                        <LoanSummary/>
                    </Row>
                    <Row>
                        <Col className="h3" md={12}>History</Col>
                    </Row>
                    <Row>
                        <LoanHistory/>
                    </Row>
                </Col>
            </Row>
            </Form>
        </Container>
    </>)
}

export default LoanCreate