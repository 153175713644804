import React, { useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import ToastNotification from "../components/utils/Common/ToastNotification";
import { LOGIN_MESSAGES } from "../components/helpers/UserFacingMessages";
import { authLogin } from "../redux/actions/auth";
import { closeToast } from "../redux/actions/ui";


export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { auth, ui } = useSelector(state => state);
  const { loggedIn } = auth;
  const { toasts } = ui;
  const { login } = toasts;

  const { TITLE } = LOGIN_MESSAGES;


  useEffect(() => {
    if (loggedIn) {
      navigate('/summary', { replace: true });
    }
  }, [loggedIn, navigate])

  const handleLogin = () => {
    dispatch(authLogin());
  };

  const handleCloseToast = () => {
    dispatch(closeToast());
  }

  return (
    <>
      <Container className="vh-100">
        <Row className="vh-100 d-flex align-content-center flex-wrap">
          <Col className="align-self-center">
            <div className="h3">Sign In</div>
            <Button className="btn" onClick={handleLogin}>
              <img src="https://img.icons8.com/ios-filled/50/FFFFFF/google-logo.png" alt="google icon" />
              <span> Continue with Google</span>
            </Button>
          </Col>
        </Row>
      </Container>
      {login.showToast && <ToastNotification show={login.showToast} setShow={handleCloseToast} variant={login.variant} labels={{ title: TITLE, description: login.label }} />}
    </>
  );
}