export const types = {
  ACCOUNT_GET_ACCOUNTS: '[ACCOUNT] GET_ACCOUNTS',
  ACCOUNT_SAVE_ACCOUNT: '[ACCOUNT] SAVE_ACCOUNT',
  ACCOUNT_SAVE_ACCOUNTS: '[ACCOUNT] SAVE_ACCOUNTS',
  ACCOUNT_CREATED_ACCOUNT: '[ACCOUNT] CREATED_ACCOUNT',

  AUTH_LOGIN: '[LOGIN] LOGIN',
  AUTH_LOGOUT: '[LOGIN] LOGOUT',

  FUNDS_GET_FUNDS: '[FUNDS] GET_FUNDS',
  FUNDS_SAVE_FUNDS: '[FUNDS] SAVE_FUNDS',
  FUNDS_SAVE_FUND: '[FUNDS] SAVE_FUND',
  FUNDS_SAVE_DEFAULT_FUND: '[FUNDS] SAVE_DEFAULT_FUND',

  LOAN_CREATED_LOAN: '[LOANS] CREATED_LOAN',
  LOANS_SAVE_LOANS: '[LOANS] SAVE_LOANS',

  COLLECTION_GET_COLLECTION: '[COLLECTION] GET_COLLECTIONS',
  COLLECTIONS_SAVE_COLLECTIONS: '[COLLECTION] SAVE_COLLECTIONS',
  COLLECTION_GET_VALUATIONS: '[COLLECTION] GET_VALUATIONS',
  COLLECTION_SAVE_COLLECTION_DETAILS: '[COLLECTION] SAVE_COLLECTION_DETAILS',
  COLLECTION_SAVE_ANALYTICS: '[COLLECTION] SAVE_ANALYTICS',
  COLLECTION_SAVE_TRADES: '[COLLECTION] SAVE_TRADES',
  COLLECTION_LOAD_INCREMENT: '[COLLECTION] LOAD_INCREMENT',
  COLLECTION_SAVE_FAVORITE: '[COLLECTION] SAVE_FAVORITE',
  COLLECTION_SAVE_OLHC: '[COLLECTION] SAVE_OLHC',


  NFT_GET_BLACKLIST: '[NFT] GET_NFT_BLACKLIST',
  NFT_SAVE_BLACKLISTED_NFT: '[NFT] SET_BLACKLISTED_NFT',

  SUMMARY_GET_SUMMARY: '[SUMMARY] GET_SUMMARY',

  UI_OPEN_LOGIN_TOAST: '[UI] OPEN_TOAST',
  UI_CLOSE_LOGIN_TOAST: '[UI] CLOSE_TOAST',
  UI_OPEN_SAVED_ACCOUNT_TOAST: '[UI] OPEN_SAVED_ACCOUNT_TOAST',
};