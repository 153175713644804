
import thunk from 'redux-thunk';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import { accountReducer } from '../reducers/accountReducer';
import { authReducer } from '../reducers/authReducer';
import { collectionsReducer } from '../reducers/collectionsReducer';
import { fundsReducer } from '../reducers/fundsReducer';
import { loansReducer } from '../reducers/loansReducer';
import { nftsReducer } from '../reducers/nftReducer';
import { summaryReducer } from '../reducers/summaryReducer';
import { uiReducer } from '../reducers/uiReducers';

//Allows redux make asynchronous requests
const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;


const reducers = combineReducers({
  account: accountReducer,
  auth: authReducer,
  fund: fundsReducer,
  loan: loansReducer,
  collection: collectionsReducer,
  nft: nftsReducer,
  summary: summaryReducer,
  ui: uiReducer,
});

export const store = createStore(
  reducers,
  composeEnhancers(
    applyMiddleware(thunk)
  )
);