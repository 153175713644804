import { types } from "../types/types";

const initialState = {
  collections: null,
  collection: {},
  increment: 0,
  analytics: [],
  trades: [],
  owner: null,
  olhc: {},
};

export const collectionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.COLLECTIONS_SAVE_COLLECTIONS:
      return {
        ...state,
        collections: action.payload,
      };
    case types.COLLECTION_SAVE_COLLECTION_DETAILS:
      return {
        ...state,
        collection: action.payload,
        owner: action.payload.contract_address,
      };
    case types.COLLECTION_SAVE_ANALYTICS:
      return {
        ...state,
        analytics: action.payload,
      };
    case types.COLLECTION_SAVE_TRADES:
      return {
        ...state,
        trades: action.payload,
      };
    case types.COLLECTION_LOAD_INCREMENT:
      return {
        ...state,
        increment: state.increment++,
      };
    case types.COLLECTION_SAVE_OLHC:
      return {
        ...state,
        olhc: action.payload,
      };
    default:
      return state;
  }
};