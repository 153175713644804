import { useState,useEffect } from "react";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import axios from 'axios';
// import { useToasts } from 'react-toast-notifications'
const token = localStorage.getItem('token') || "";

async function postData(formData) {
    try {
        let formattedForm = formData;
        const result = await axios.post(process.env.REACT_APP_API_URL + `/collections`, formattedForm, {
            headers: {
              'x-token': token,
            }})
        return result;
    } catch (error) {
        return error;
    }
}

function CollectionCreate() {
    const [collection, setCollection] = useState({})
    const [isSubCollection, setSubCollection] = useState(false)
    const [showForm, setShowForm] = useState(false)
    const [subColType, setSubColType] = useState(false)
    // const { addToast } = useToasts()

    const handleSubmit = (e) => {
        console.log('fire post');
        let result = postData(collection);
        e.preventDefault();
        if (result.statusCode === 200) {
            e.target.reset();
            setCollection({});
        }
    }

    const handleSubCollection = (e) => {
        collection.subcollection = e;
        setSubCollection(e === 'true');
    }

    const handleSubCollectionType = (e) => {
        collection.subcollection_type = e;
        setSubColType(e)
    }

    const SubCollectionForm = (SubColType) => {
        return <>
        <Form.Group className="mb-3">
            <Form.Label>Sub-Collection Name</Form.Label>
            <Form.Control type="text"
                name="collection[subcollection_name]"
                onChange={e => setCollection({ ...collection, subcollection_name: e.target.value })}
                placeholder="Sub-Collection Name" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
            <Form.Label>Sub-Collection Type</Form.Label>
            <DropdownButton onSelect={e => handleSubCollectionType(e)} id="dropdown-basic-button" name="collection[subcollection_type]" title={subColType ? subColType : 'Please Select a Sub-Collection Type'}>
                <Dropdown.Item key='option0' eventKey={'artblocks'}>Art Blocks</Dropdown.Item>
                <Dropdown.Item key='option1' eventKey={'emblemvault'}>Emblem Vault</Dropdown.Item>
            </DropdownButton>
        </Form.Group>
        {subColType === 'artblocks' ? (
            <Form.Group className="mb-3">
                <Row>
                <Form.Label>Sub-Collection Range</Form.Label>
                <Col>
                <Form.Control type="text"
                    name="collection[subcollection_min]"
                    onChange={e => setCollection({ ...collection, subcollection_min: e.target.value })}
                    placeholder="Minimum Value" />
                    </Col>
                <Col>
                <Form.Control type="text"
                    name="collection[subcollection_max]"
                    onChange={e => setCollection({ ...collection, subcollection_max: e.target.value })}
                    placeholder="Maximum Value" />
                </Col>
                </Row>
            </Form.Group>
        ) : (
        <Form.Group className="mb-3">
            <Form.Label>Sub-Collection Query</Form.Label>
            <Form.Control type="text"
                name="collection[subcollection_query]"
                onChange={e => setCollection({ ...collection, subcollection_query: e.target.value })}
                placeholder="Sub-Collection Query" />
        </Form.Group>)}
    </>
    }

    useEffect(()=>{
        setShowForm(isSubCollection);
    },[isSubCollection]);

    return (
        <Container className="text-start">
            <br />
            <Row>
                <Col>
                    <div className="h2">Track a Collection</div>
                    <br />
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Is Sub-Collection</Form.Label>
							<DropdownButton onSelect={e => handleSubCollection(e)} id="dropdown-basic-button" title={isSubCollection ? 'Yes' : 'No'}>
                                <Dropdown.Item key='option0' eventKey={true}>Yes</Dropdown.Item>
                                <Dropdown.Item key='option1' eventKey={false}>No</Dropdown.Item>
							</DropdownButton>
						</Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Collection Address</Form.Label>
                            <Form.Control type="text"
                                name="collection[contract_address]"
                                onChange={e => setCollection({ ...collection, contract_address: e.target.value })}
                                placeholder="Collection Address" />
                        </Form.Group>
                        {showForm ? SubCollectionForm() : null}
                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Col></Row></Container>
    );
}

export default CollectionCreate