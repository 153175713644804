import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';

function Filter(props) {
    const [startDate, setStartDate] = useState(props?.minDate);
    const [endDate, setEndDate] = useState(props?.maxDate);
    const [interval, setInterval] = useState('day');

    const handleSubmit = (e) => {
        const {
            setMinDate,
            setMaxDate,
            setInterval
        } = props.updateInterval;
        setMinDate(e.target.dateFrom.value);
        setMaxDate(e.target.dateTo.value);
        setInterval(interval);
        e.preventDefault();
    }

    const changeInterval = (e) => {
        console.log(e);
        setInterval(e);
    }

    return (
        <>
            <Row className="filter">
                <Col md="6">
                    <div className="h3" href="#">{props?.name}</div>
                </Col>
                <Col md="6">
                    <Form onSubmit={e => { handleSubmit(e) }}>
                        <Stack direction="horizontal" gap={2}>
                            <DropdownButton title={`1 ${interval}`} onSelect={e => changeInterval(e)} name="interval" id="nav-dropdown">
                                <Dropdown.Item eventKey="hour">1 hour</Dropdown.Item>
                                <Dropdown.Item eventKey="day">1 day</Dropdown.Item>
                            </DropdownButton>
                            <Form.Control type="date" name="dateFrom" value={startDate} onChange={e => setStartDate(e.target.value)} placeholder="Date From" />
                            <Form.Control type="date" name="dateTo" value={endDate} onChange={e => setEndDate(e.target.value)} placeholder="Date To" />
                            <Button variant="outline-success" type="submit">Update</Button>
                        </Stack>
                    </Form>
                </Col>
            </Row>
        </>
    )
}

export default Filter