import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import News from '../components/news/News';

function Home() {
    console.log('Home Component');
    return (
        <Container>
            <br/>
        <Row className="text-start">
          <Col md={8}>
            <Row>
                <div className="h2">Fund 0 Summary</div>
                <p>Last updated 5 minutes ago</p>
            </Row>
            <Row>
                <Col>
                    <div className="h4">Cost Basis</div>
                    <div className="h2 text-muted">81.75 ETH</div>
                </Col>
                <Col>
                    <div className="h4">Current Value</div>
                    <div className="h2 text-muted">150 ETH</div>
                </Col>
                <Col>
                    <div className="h4">Unrealized Value</div>
                    <div className="h2 text-muted">+83%</div>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col>
                    <div className="h4">Liquid Assets</div>
                    <div className="h2 text-muted">81.75 ETH</div>
                    <Table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Username</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>1</td>
                            <td>Mark</td>
                            <td>Otto</td>
                            <td>@mdo</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Jacob</td>
                            <td>Thornton</td>
                            <td>@fat</td>
                        </tr>
                        
                        </tbody>
                    </Table>
                </Col>
                <Col>
                    <div className="h4">Illiquid Assets</div>
                    <div className="h2 text-muted">103 ETH</div>
                    <Table>
                        <thead>
                        <tr>
                            <th>Asset</th>
                            <th>Purchase $</th>
                            <th>Curr. $</th>
                            <th>ROI</th>
                            <th>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>BAYC #7156</td>
                            <td>100 ETH</td>
                            <td>150 ETH</td>
                            <td>+50%</td>
                            <td><i className="bi bi-graph-up-arrow"></i></td>
                        </tr>
                        <tr>
                            <td>BAYC #8817</td>
                            <td>1000 ETH</td>
                            <td>1500 ETH</td>
                            <td>+50%</td>
                            <td><i className="bi bi-graph-up-arrow"></i></td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
          </Col>
          <Col md={4}>
            <News/>
          </Col>
        </Row>
      </Container>
    );
  }
  
export default Home