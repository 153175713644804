import { types } from "../types/types";

const initialState = {
  summary: {},
};

export const summaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SUMMARY_GET_SUMMARY:
      return {
        ...state,
      };
    default:
      return state;
  }
};