import { types } from "../types/types";

const initialState = {
  blacklist: null,
};

export const nftsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.NFT_GET_BLACKLIST:
      return {
        ...state,
        blacklist: action.payload,
      };
    default:
      return state;
  }
};
