import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

import './ToastNotification.css';


const ToastNotification = ({ show, setShow, labels, variant }) => {
  return (
    <ToastContainer position="top-end"className="p-3">
      <Toast  bg={variant.toLowerCase()}  onClose={() => setShow(false)} show={show} delay={3000} autohide>
        <Toast.Body className='description'>{labels.description}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastNotification;
