import { now } from "moment";
import { types } from "../types/types";

const initialState = {
  account: null,
  accounts: null,
  updatedTime: null,
};

export const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ACCOUNT_CREATED_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case types.ACCOUNT_SAVE_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        updatedTime: now(),
      };
    case types.ACCOUNT_SAVE_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    default:
      return state;
  }
};