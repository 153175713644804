import { createChart, CrosshairMode } from 'lightweight-charts';
import React, { useEffect, useRef, useState } from "react";

function calculateSMA(data, count){
    var avg = function(data) {
        var sum = 0;
        for (var i = 0; i < data.length; i++) {
            sum += data[i].close;
        }
        return sum / data.length;
    };
    var result = [];
    for (var i=count - 1, len=data.length; i < len; i++){
        var val = avg(data.slice(i - count + 1, i));
        result.push({ time: data[i].time, value: val});
    }
    return result;
    }

function LineChart({props}) {
    const chartContainerRef = useRef(null);
    const chart = useRef(null);
    const resizeObserver = useRef(null);

    let [candleSeries, setCandleSeries] = useState({});
    let [smaLine, setSmaLine] = useState({});
    let [volumeSeries, setVolumeSeries] = useState({});

    
    useEffect(() => {
        chart.current = createChart(chartContainerRef.current, {
            width: chartContainerRef.current.clientWidth,
            height: chartContainerRef.current.clientHeight,
            layout: {
                backgroundColor: '#253248',
                textColor: 'rgba(255, 255, 255, 0.9)',
            },
            grid: {
                vertLines: {
                    color: '#334158',
                },
                horzLines: {
                    color: '#334158',
                },
            },
            crosshair: {
                mode: CrosshairMode.Normal,
            },
            priceScale: {
                borderColor: '#485c7b',
            },
            timeScale: {
                borderColor: '#485c7b',
            },
        });
    },[]);
    
    useEffect(() => {
        if(Object.keys(candleSeries).length > 0) {
            chart.current.removeSeries(candleSeries);
            chart.current.removeSeries(smaLine);
            chart.current.removeSeries(volumeSeries);
        }
        // chart?.current?.removeSeries(smaLine);
        // chart?.current?.removeSeries(volumeSeries);

        setCandleSeries(chart.current.addCandlestickSeries());

        setSmaLine(chart.current.addLineSeries({
            color: 'rgba(4, 111, 232, 1)',
            lineWidth: 2,
        }));

        setVolumeSeries(chart.current.addHistogramSeries({
            color: '#334158',
            lineWidth: 2,
            priceFormat: {
                type: 'volume',
            },
            overlay: true,
            scaleMargins: {
                top: 0.8,
                bottom: 0,
            },
        }));
    }, [props]);

    useEffect(()=>{
        if(Object.keys(props).length > 0) {
            candleSeries.setData(props.ohlc);
            let smaLength = 20;
            if(props.interval === 'day') {
                smaLength = 20;
            } else {
                smaLength = 200;
            }
            let smaData = calculateSMA(props.ohlc, smaLength);
            smaLine.setData(smaData);
            volumeSeries.setData(props.vol)
        }
    },[candleSeries]);

    useEffect(()=>{
        resizeObserver.current = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            chart.current.applyOptions({ width, height });
            setTimeout(() => {
                chart.current.timeScale().fitContent();
            }, 0);
        });
    
        resizeObserver.current.observe(chartContainerRef.current);
    
        return () => {
            resizeObserver.current.disconnect();
        } 
    })



        return (
            <div ref={chartContainerRef} className="chart-container" />
        );

  }
  
export default LineChart