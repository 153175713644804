import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import News from '../../components/news/News';
import { dateFromNow, fixDecimals, isArrayWithLength } from '../../components/utils/utils';
import { createBlacklistedNft } from '../../redux/actions/nft';
import { saveSelectedAccount } from '../../redux/actions/account';
// import LineChart from '../components/LineChart';

function AccountSummary() {
  const dispatch = useDispatch();
  const { account, updatedTime } = useSelector(state => state.account);

  const [balances, setBalances] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [currentValue, setCurrentValue] = useState(0.0);
  const [balancesTotal, setBalancesTotal] = useState(0.0);
  const [nftsTotal, setNftsTotal] = useState(0.0);
  const [nftsTotalNumber, setNftsTotalNumber] = useState(0);
  const [accountSummary, setAccountSummary] = useState(null);

  useEffect(() => {
    setAccountSummary(account);
  }, [account]);

  useEffect(() => {
    if (!isArrayWithLength(accountSummary?.accountBalances)
      && !accountSummary?.nfts
    ) {
      return setCurrentValue(0);
    }
    const balanceTotal = accountSummary.accountBalances?.reduce(
      (currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0;
    const nftTotal = accountSummary.nfts?.ownedNfts?.reduce(
      (currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0;

    const nftTotalNumber = accountSummary.nfts.totalCount;

    setNftsTotalNumber(nftTotalNumber);

    setBalancesTotal(balanceTotal);
    setNftsTotal(nftTotal);

    setCurrentValue(balanceTotal + nftTotal);
  }, [accountSummary]);

  useEffect(() => {
    setBalances(accountSummary?.accountBalances ?? null);
    setNfts(accountSummary?.nfts?.ownedNfts ?? null);
  }, [accountSummary, setNfts, setBalances]);

  const handleImage = async (imgAPIUrl) => {
    if (imgAPIUrl)
      window.open(imgAPIUrl);
  };

  const handleBlacklist = (nft) => {
    dispatch(createBlacklistedNft({
      hexId: nft.id.tokenId,
      contractAddress: nft.contract.address,
      title: nft.title,
      description: nft.description,
    }));
    const data = {
      ...account,
      nfts: {
        ...account.nfts,
        ownedNfts: account.nfts.ownedNfts.filter(ownednft => ownednft.id.tokenId !== nft.id.tokenId),
        totalCount: account.nfts.totalCount - 1
      }
    };
    dispatch(saveSelectedAccount(data));
  };

  return (
    <Container>
      <br />
      <Row className="text-start">
        <Col md={8}>
          <Row>
            <Col>
              <div className="h2">{accountSummary?.name} Summary</div>
              <p className="blue">Last update {dateFromNow(updatedTime)}</p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={5}>
              <div className="h6">Current Value</div>
              <div className="h2">$ {fixDecimals(currentValue, 3)}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Total NFTs</div>
              <div className="h2">{nftsTotalNumber}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Current Value (Tokens)</div>
              <div className="h2">$ {fixDecimals(balancesTotal, 3)}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Current Value (NFTs)</div>
              <div className="h2">$ {fixDecimals(nftsTotal, 3)}</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="h2">NFTs</div>
              {/* <LineChart refId="1" className="chart-container"/> */}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {nfts ?
                <Table className="summary">
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Cost Basis</th>
                      <th>Current Value</th>
                      <th>ROI</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      nfts.map((nft, index) => (
                        <tr key={`nft-value${index}`}>
                          <td>{nft.title || "N/A"}</td>
                          <td>{fixDecimals(nft.price, 2)} ETH</td>
                          <td>$ {fixDecimals(nft.USD, 3)}</td>
                          <td><span className="green">+25%</span></td>
                          <td>
                            <i className="bi bi-box-arrow-up-right pointer" title="Watch image" onClick={() => handleImage(nft.media[0]?.raw)}></i>
                            <i className="mt-2 ms-2 bi bi-x-lg pointer" title="Send to Blacklist" onClick={() => handleBlacklist(nft)}></i>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : <p>No NFTS found</p>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="h2">Crypto</div>
              {/* <LineChart refId="1" className="chart-container"/> */}
            </Col>
          </Row>
          <Row>
            <Col>
              {isArrayWithLength(balances) ?
                <Table className="summary">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Asset</th>
                      <th>Cost Basis</th>
                      <th>Current Value</th>
                      <th>ROI</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      balances.map((balance, index) => (
                        <tr key={`account-balance-${index}`}>
                          <td>{balance.name}</td>
                          <td>N/A</td>
                          <td>{fixDecimals(balance.value, 2)} {balance.symbol} ($ {fixDecimals(balance.USD, 3)})</td>
                          <td><span className="green">+25%</span></td>
                          <td><i className="bi bi-box-arrow-up-right"></i></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : <p>No cryptos found</p>
              }
            </Col>
          </Row>
        </Col>
        <Col>
          <News />
        </Col>
      </Row>
    </Container>
  );
}

export default AccountSummary;
