import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { getAccountsByFund } from '../../redux/actions/account';
import News from '../../components/news/News';
import { dateFromNow, fixDecimals, isArrayWithLength } from '../../components/utils/utils';
// import LineChart from '../components/LineChart';

function Summary() {
  const [currentValue, setCurrentValue] = useState(0.0);
  const [balances, setBalances] = useState(null);
  const [nfts, setNfts] = useState(null);
  const [filteredAccounts, setFilteredAccounts] = useState(null);
  const [balancesTotal, setBalancesTotal] = useState(0.0);
  const [nftsTotal, setNftsTotal] = useState(0.0);
  const [nftsTotalNumber, setNftsTotalNumber] = useState(0);

  const dispatch = useDispatch();
  const { selectedFund } = useSelector(state => state.fund);
  const { accounts, updatedTime } = useSelector(state => state.account);
  const { blacklist } = useSelector(state => state.nft);

  useEffect(() => {
    const fetchAccounts = () => {
      if (!selectedFund) {
        return;
      }
      dispatch(getAccountsByFund(selectedFund._id));
    }
    fetchAccounts();
  }, [dispatch, selectedFund]);

  useEffect(() => {
    if (!isArrayWithLength(accounts)) {
      setFilteredAccounts(null);
      return;
    }

    const data = accounts.map(account => {
      let c = 0;
      return {
        ...account,
        nfts: {
          ownedNfts: account.nfts?.ownedNfts?.filter(nft => {
            if (!blacklist.find(item => item.hexId === nft.id.tokenId)) {
              return { ...nft };
            }
            c++;
            return null;
          }) ?? null,
          totalCount: account.nfts?.totalCount ? account.nfts.totalCount - c : 0,
        }
      }
    });

    setFilteredAccounts(data);
  }, [accounts, blacklist, setFilteredAccounts])


  useEffect(() => {
    if (!isArrayWithLength(filteredAccounts)) {
      setCurrentValue(0);
      setBalancesTotal(0);
      setNftsTotal(0);
      return;
    }

    const balanceTotal = filteredAccounts

      .map(account => account.accountBalances?.reduce(
        (currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0
      ).reduce((current, next) => current + next, 0);

    const nftTotal = filteredAccounts
      .filter(account => account)
      .map(account => account.nfts?.ownedNfts?.reduce(
        (currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0
      ).reduce((current, next) => current + next, 0);



    setBalancesTotal(balanceTotal);
    setNftsTotal(nftTotal);

    setCurrentValue(balanceTotal + nftTotal);

  }, [filteredAccounts, setCurrentValue]);

  useEffect(() => {
    if (!isArrayWithLength(filteredAccounts)) {
      setNftsTotalNumber(0);
      setNfts(null);
      setBalances(null);
      return;
    }

    const nftTotalNumber = filteredAccounts
      .filter(account => account)
      .filter(account => account.nfts)
      ?.reduce((value, current) => value + current.nfts.totalCount, 0) ?? 0;

    setNftsTotalNumber(nftTotalNumber);

    const valuedNfts = filteredAccounts.flat()
      ?.filter(account => account.nfts)
      ?.map(account => account.nfts.ownedNfts)
      ?.flat()
      ?.filter(account => account);

    const valuedBalances = filteredAccounts.flat()
      ?.filter(account => isArrayWithLength(account.accountBalances))
      ?.flat()
      ?.map(balance => balance.accountBalances).flat();

    //#region Get no repited data 
    let convertedToMapStructure = valuedBalances.map(item => {
      return [item.symbol, item]
    });
    var mapData = new Map(convertedToMapStructure);
    const fixedData = [...mapData.values()];
    //#endregion

    //#region Sum values of repeted data
    const duplicateRemoved = fixedData.map(item => ({
      ...item,
      value: valuedBalances.filter(p => p.symbol === item.symbol)
        .reduce((acc, current) => acc + current.value, 0),
      USD: valuedBalances.filter(p => p.symbol === item.symbol)
        .reduce((value, current) => value + current.USD, 0),
    }));
    //#endregion

    setBalances(duplicateRemoved);
    setNfts(valuedNfts);
  }, [filteredAccounts, setNfts, setBalances]);

  const handleImage = async (imgAPIUrl) => {
    const response = await axios.get(imgAPIUrl);
    if (response.data)
      window.open(response.data.image);
  };

  return (
    <Container>
      <br />
      <Row className="text-start">
        <Col md={8}>
          <Row>
            <Col>
              <div className="h2">{selectedFund?.name} Summary</div>
              <p className="blue">Last update {dateFromNow(updatedTime)}</p>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={5}>
              <div className="h6">Current Value (Total)</div>
              <div className="h2">$ {fixDecimals(currentValue, 2)}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Total NFTs</div>
              <div className="h2">{nftsTotalNumber}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Current Value (Tokens)</div>
              <div className="h2">$ {fixDecimals(balancesTotal, 2)}</div>
            </Col>
            <Col md={5}>
              <div className="h6">Current Value (NFTs)</div>
              <div className="h2">$ {fixDecimals(nftsTotal, 2)}</div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <div className="h2">NFTs</div>
              {/* <LineChart refId="1" className="chart-container"/> */}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              {nfts ?
                <Table className="summary">
                  <thead>
                    <tr>
                      <th>Asset</th>
                      <th>Cost Basis</th>
                      <th>Current Value</th>
                      <th>ROI</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      nfts.map((nft, index) => (
                        <tr key={`nft-value${index}`}>
                          <td>{nft.title || "N/A"}</td>
                          <td>N/A</td>
                          <td>{fixDecimals(nft.price, 2)} ETH ($ {fixDecimals(nft.USD, 3)})</td>
                          <td><span className="green">+25%</span></td>
                          <td><i className="bi bi-box-arrow-up-right" onClick={() => handleImage(nft.tokenUri.gateway)}></i></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : <p>No NFTS found</p>
              }
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="h2">Crypto</div>
              {/* <LineChart refId="1" className="chart-container"/> */}
            </Col>
          </Row>
          <Row>
            <Col>
              {balances ?
                <Table className="summary">
                  <thead>
                    <tr>
                      <th style={{ width: "20%" }}>Asset</th>
                      <th>Cost Basis</th>
                      <th>Current Value</th>
                      <th>ROI</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      balances.map((balance, index) => (
                        <tr key={`account-balance-${index}`}>
                          <td>{balance.name}</td>
                          <td>N/A</td>
                          <td>{fixDecimals(balance.value, 2)} {balance.symbol} ($ {fixDecimals(balance.USD, 3)})</td>
                          <td><span className="green">+25%</span></td>
                          <td><i className="bi bi-box-arrow-up-right"></i></td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>
                : <p>No cryptos found</p>
              }
            </Col>
          </Row>
        </Col>
        <Col>
          <News />
        </Col>
      </Row>
    </Container>
  );
}

export default Summary;
