import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import News from '../../components/news/News';
import { getAccountsByFund, saveSelectedAccount } from '../../redux/actions/account';
import { fixDecimals, isArrayWithLength } from '../../components/utils/utils';

function Account() {
	const [values, setValues] = useState([]);
	const [validatedAccountsbyNfts, setValidatedAccountsbyNfts] = useState([]);

	const dispatch = useDispatch();
	const { accounts } = useSelector(state => state.account);
	const { selectedFund } = useSelector(state => state.fund);
	const { blacklist } = useSelector(state => state.nft);

	useEffect(() => {
		const fetchAccounts = () => {
			if (!selectedFund) {
				return;
			}
			dispatch(getAccountsByFund(selectedFund._id));
		}
		fetchAccounts();
	}, [dispatch, selectedFund]);

	useEffect(() => {
		if (!isArrayWithLength(accounts)) {
			setValidatedAccountsbyNfts(null);
			return setValues([]);
		}

		const data = accounts.map(account => {
			let c = 0;
			return {
				...account,
				nfts: {
					ownedNfts: account.nfts?.ownedNfts?.filter(nft => {
						if (!blacklist.find(item => item.hexId === nft.id.tokenId)) {
							return { ...nft };
						}
						c++;
						return null;
					}) ?? null,
					totalCount: account.nfts?.totalCount ? account.nfts.totalCount - c : 0,
				}
			}
		});

		setValidatedAccountsbyNfts(data);
	}, [accounts, blacklist, dispatch]);


	useEffect(() => {
		if (!isArrayWithLength(validatedAccountsbyNfts)) {
			return setValues([]);
		}

		const data = validatedAccountsbyNfts.map(account => {
			const value = account.accountBalances
				?.reduce(
					(currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0;
			const nftValue = account.nfts?.ownedNfts
				?.reduce(
					(currentValue, nexItem) => currentValue + nexItem.USD, 0) ?? 0;
			return {
				...account,
				tokensUSD: value,
				nftsUSD: nftValue,
			}
		});
		setValues(data);
	}, [validatedAccountsbyNfts]);

	const handleSelectAccount = (account) => {
		dispatch(saveSelectedAccount(account));
	};

	return (
		<Container className="text-start">
			<br />
			<Row>
				<Col md={8}>
					<Stack direction="horizontal" gap={3}>
						<div><div className="h2">Accounts</div></div>
						<div className="ms-auto"></div>
						<div><Link to={"/create-account"}>Track an Account</Link></div>
					</Stack>
					<br />
					<Table className="summary">
						<thead>
							<tr>
								<th>Account Name</th>
								<th>Total NFTs</th>
								<th>Current Value (NFTs)</th>
								<th>Current Value (Tokens)</th>
							</tr>
						</thead>
						<tbody>
							{values?.map((account, index) => (
								<tr key={`tr-${index}`}>
									<td><Link to={"/account-summary"} onClick={() => handleSelectAccount(account)}>{account.name}</Link></td>
									<td>{account.nfts?.totalCount ?? 0}</td>
									<td>$ {fixDecimals(account.nftsUSD, 3)}</td>
									<td>$ {fixDecimals(account.tokensUSD, 3)}</td>
								</tr>
							))}
						</tbody>
					</Table>
				</Col>
				<Col md={4}>
					<News />
				</Col>
			</Row>
		</Container>
	);
}

export default Account;
