import axios from 'axios';
import { types } from '../types/types';
import { showToast } from './ui';

const token = localStorage.getItem('token') || "";

export const createAccount = (account) => {
  return async (dispatch) => {
    try {
      const result = await axios.post(`${process.env.REACT_APP_API_URL}/accounts`, account, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        }
      });
      if (result.data.ok) {
        dispatch(createdAccount(result.data.account));
        dispatch(showToast({
          variant: 'Success',
          label: 'Account created successfully!'
        }));
      } else {
        dispatch(showToast({
          variant: 'Danger',
          label: 'Please, try again!',
        }));
      }
    } catch (error) {
      dispatch(showToast({
        variant: 'Danger',
        label: 'Please, try again!',
      }));
    }
  };
};

export const getAccounts = () => {
  return async (dispatch) => {
    try {
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/accounts`, {
        headers: {
          'x-token': token,
        }
      });
      if (result.data.ok) {
        dispatch(saveAccounts(result.data.accounts));
      }
    } catch (error) {
      dispatch(showToast({
        variant: 'Danger',
        label: 'Please, try again!',
      }));
    }
  };
};

export const getAccountsByFund = (fund) => {
  return async (dispatch) => {
    try {
      dispatch(saveAccounts(null));
      const result = await axios.get(`${process.env.REACT_APP_API_URL}/accounts/fund/${fund}`, {
        headers: {
          'x-token': token,
        }
      });
      if (result.data.ok) {
        const accounts = result.data.accounts;
        dispatch(saveAccounts(accounts));
      }
    } catch (error) {
      dispatch(showToast({
        variant: 'Danger',
        label: 'Please, try again!',
      }));
    }
  };
};

export const saveSelectedAccount = (account) => {
  return (dispatch) => {
    dispatch(saveAccount(account));
  };
};

const createdAccount = (account) => ({
  type: types.ACCOUNT_CREATED_ACCOUNT,
  payload: account,
});

const saveAccounts = (accounts) => ({
  type: types.ACCOUNT_SAVE_ACCOUNTS,
  payload: accounts,
});

const saveAccount = (account) => ({
  type: types.ACCOUNT_SAVE_ACCOUNT,
  payload: account,
});
