import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Account from './pages/Account/Accounts';
import AccountCreate from './pages/Account/AccountCreate';
import Summary from './pages/Summary/Summary';

import Collection from './pages/Collection/Collections';
import CollectionDetails from './pages/Collection/CollectionDetails';
import CollectionCreate from './pages/Collection/CollectionCreate';

import Loan from './pages/Loan/Loans';
import LoanDetails from './pages/Loan/LoanDetails.js';
import LoanCreate from './pages/Loan/LoanCreate';


import Valuation from './pages/Valuation/Valuations';
import PrivateRoute from './components/PrivateRoute';
import Navigation from './components/Navigation';
import UserProvider from "./providers/UserProvider";
import AccountSummary from './pages/Summary/AccountSummary';
import Blacklist from './pages/Nfts/Blacklist';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store/store';

function App() {
  return (
    <UserProvider>
      <Provider store={store}>
        <div className="App">
          <Router>
            <Navigation />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route exact path="/" element={<PrivateRoute />}>
                <Route path="/home" element={<Home />} />
                <Route exact path="/summary" element={<Summary />} />
                <Route exact path="/account-summary/" element={<AccountSummary />} />
                <Route path="/account" element={<Account />} />
                <Route path="/create-account" element={<AccountCreate />} />
                
                <Route path="/valuation">
                  <Route path="" element={<Valuation />} />
                  <Route path=":contractAddress" element={<Valuation />} />
                </Route>
                <Route path="/nft-blacklist" element={<Blacklist />} />
                <Route path="/collection">
                  <Route path="" element={<Collection />} />
                  <Route path="create" element={<CollectionCreate />} />
                  <Route path=":data" element={<CollectionDetails />} />
                </Route>
                <Route path="/loan">
                  <Route path="" element={<Loan />} />
                  <Route path="create" element={<LoanCreate />} />
                  <Route path=":data" element={<LoanDetails />} />
                </Route>
                
              </Route>
            </Routes>
          </Router>
        </div>
      </Provider>
    </UserProvider>
  );
}

export default App;
