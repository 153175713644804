import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import axios from "axios";
import Container from 'react-bootstrap/Container';
import moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import ListGroup from 'react-bootstrap/ListGroup';
import Button from 'react-bootstrap/Button';
import SubNavigation from '../../components/SubNavigation';

function LoanRecord() {

}

function LoanDetails() {
    return (<>
            <SubNavigation data={[
            { 'Trading': '/trading' },
            { 'Lending': '/lending' },
            { 'Opportunities': '/opportunities' },
        ]}></SubNavigation>
        <Container className="text-start">
            <Row>
                <Col md={8}>
                    <h1>Create a Loan</h1>
                </Col>
                <Col md={4}></Col>
            </Row>
        </Container>
    </>)
}

export default LoanDetails