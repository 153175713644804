import { useEffect, useState } from "react";
import axios from "axios";
import Moment from 'react-moment';
import moment from 'moment';
import Badge from 'react-bootstrap/Badge';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './News.css';

async function fetchData(tags = ['crypto', 'nfts'], hoursAgo = 0) {
    const startFrom = moment().subtract(hoursAgo, 'hours').format('YYYY-MM-DD hh:mm:ss');
    let newsTags = encodeURIComponent(tags.join(' OR '));
    try {
        const result = await axios.get(process.env.REACT_APP_API_URL + `/news?language=en&q=(${newsTags})&from=${startFrom}&sortBy=publishedAt`)
        console.log(result);
        return result;
    } catch (error) {
        return error;
    }
}

function NewsBlock({ title, description, sourceLink, imageLink, dateFrom, source }) {
    return (
        <div className="news">
            <div className="h6">{title}</div>
            <div>
                <p className="description">{description}  -  <a href={sourceLink} target="_blank" rel="noreferrer">{source?.name}</a></p>
                <div className="date"><small><Moment fromNow>{dateFrom}</Moment></small></div>
            </div>
            <br />
        </div>
    )
}

function News(props) {
    let [news, setNews] = useState([]);
    useEffect((props) => {
        fetchData(props?.tags, props?.daysAgo).then((response) => {
            setNews(response.data.data);
        });
    }, []);

    return (
        <>
            <Row>
                <Col className="h3" md={9}>News</Col>
            </Row>
            <div>
                <Badge bg="info">Crypto</Badge>&nbsp;<Badge bg="info">NFTs</Badge>&nbsp;<Badge bg="info">MAYC</Badge>&nbsp;<Badge bg="info">Mutant Ape Yacht Club</Badge>&nbsp;<span><i className="bi bi-tags"></i></span>
            </div><br />
            {news?.map((data, i) => {
                return (
                    <NewsBlock key={i} title={data.title} description={data.description} sourceLink={data.url} source={data.source} imageLink={data.urlToImage} dateFrom={data.publishedAt} />
                )
            })}
        </>
    )
}

export default News