import { types } from "../types/types";


const initialState = {
  toasts: {
    login: {
      showToast: false,
      type: '',
      label: ''
    },
    account: {
      showToast: false,
      type: '',
      label: ''
    }
  },
};

export const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UI_OPEN_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          login: {
            showToast: true,
            variant: action.payload.variant,
            label: action.payload.label,
          },
        },
      }
    case types.UI_CLOSE_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          login: {
            showToast: false,
            variant: '',
            label: '',
          },
        },
      };
    case types.UI_OPEN_SAVED_ACCOUNT_TOAST:
      return {
        ...state,
        toasts: {
          ...state.toasts,
          account: {
            showToast: true,
            variant: action.payload.variant,
            label: action.payload.label,
          },
        },
      }
    default:
      return state;
  }
};