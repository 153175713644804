import { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Moment from 'react-moment';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';

import News from '../../components/news/News';
import SubNavigation from '../../components/SubNavigation';
import { fetchAnalytics, getCollections, pushToFavorite } from "../../redux/actions/collection";


function Collection() {
  const dispatch = useDispatch();
  const { analytics, collections: unFixedCollections } = useSelector(state => state.collection);


  const [collections, setCollections] = useState({});
  const [fixedAnalytics, setAnalytics] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(1);
  const [currPage, setCurrPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(100);

  const pushFavorite = async (collection) => {
    try {
      const tempCollection = collection;
      const tempCollections = collections;

      const newFavState = !tempCollection.favorite;
      dispatch(pushToFavorite(tempCollection));
      tempCollections[tempCollection.contract_address].favorite = newFavState;
      console.log('Push Favorite', tempCollection.contract_address, tempCollection.favorite)
      console.log(tempCollections);
      setCollections(tempCollections);
      setForceUpdate(forceUpdate + 1);
    } catch (error) {
      return error;
    }
  }

  const MarkAsFavorite = (props) => {
    if (collections[props.record.contract_address]?.favorite) {
      return (
        <><i className="bi bi-star-fill" onClick={() => props.clickFn(props?.record)}></i></>
      )
    } else {
      return (
        <><i className="bi bi-star" onClick={() => props.clickFn(props?.record)}></i></>
      )
    }
  }

  useEffect(() => {
    console.log('Fetching Initial Collection Data');
    dispatch(getCollections());
    dispatch(fetchAnalytics(0));
  }, [dispatch]);

  useEffect(() => {
    console.log(`change currPage`, currPage);
    dispatch(getCollections(currPage, pageLimit));

    const analyticsResult = analytics?.map((analysis) => ({
      [analysis._id.contract_address]: analysis
    }));
    setAnalytics(analyticsResult);

  }, [analytics, currPage, dispatch, forceUpdate, pageLimit]);

  useEffect(() => {
    /** Set Contract Address as Key to Collection */
    let collectionResult = {};
    unFixedCollections?.forEach((collection) =>
      collectionResult[collection.contract_address] = collection
    );
    // writeCollection(collectionResult);
    setCollections(collectionResult);
  }, [unFixedCollections])

  useEffect(() => {
    const analyticsResult = analytics?.map((analysis) => ({
      [analysis._id.contract_address]: analysis
    }));
    setAnalytics(analyticsResult);
  }, [analytics]);


  const CollectionBlock = ({ data }) => {
    const address = data.contract_address ?? null;
    const analytics = fixedAnalytics.find(item => item[address]);

    let floorVolumeChange = 0;
    let rawVolume = 0;
    let floorPriceChange = 0;
    let last_price = 0;

    if (analytics) {
      floorPriceChange = (analytics[address].floor_price_change * 100).toFixed(2);
      rawVolume = analytics[address].last_volume;
      floorVolumeChange = (analytics[address].volume_change * 100).toFixed(2);
      last_price = analytics[address].last_price.toFixed(2);
    }

    return (<>
      <tr key={data.contract_address}>
        <td className="align-middle"><MarkAsFavorite record={data} clickFn={pushFavorite} /></td>
        <td className="align-middle"><img alt="opensea collection" className="asset rounded float-start" src={data.opensea_image_url} /></td>
        <td className="align-middle"><Link to={`/collection/${data.contract_address}|${data._id}`}>{data.collection_name}</Link><br /><small className="text-reset text-muted">Model updated <Moment fromNow>{data.metadata.valuation_model_info?.last_update}</Moment></small></td>
        <td className="align-middle">{data.opensea_total_supply}</td>
        <td className="align-middle">{last_price ? last_price + ' ETH' : 'N/A'}</td>
        <td className="align-middle"><span className={floorPriceChange > 0 ? 'green' : 'red'}>{analytics ? floorPriceChange + "%" : "N/A"} </span></td>
        <td className="align-middle">{rawVolume + ' ETH'}&nbsp; <span className={floorVolumeChange > 0 ? 'green' : 'red'}>{analytics ? floorVolumeChange + "%" : 'N/A'}</span></td>
      </tr>
    </>);
  };

  return (
    <>
      <SubNavigation data={[
        { 'Trading': '/trading' },
        { 'Lending': '/lending' }
      ]}></SubNavigation>
      <Container className="text-start">

        <br />
        <Row>
          <Col md={9}>
            <Stack direction="horizontal" gap={3}>
              <div><div className="h2">Collections</div></div>
              <div className="ms-auto"></div>
              <div><Link to={`/collection/create`}>Track a Collection</Link></div>
            </Stack>
            <br />
            <Table>
              <thead>
                <tr>
                  <th></th>
                  <th>Image</th>
                  <th>Collection</th>
                  <th>Qty.</th>
                  <th>Last Floor</th>
                  <th>Floor Change (24 hrs)</th>
                  <th>Volume Change (24 hrs)</th>
                </tr>
              </thead>
              <tbody>

                {Object.keys(collections).map((collection, index) => (
                  <CollectionBlock key={`collection-${index}`} data={collections[collection]}></CollectionBlock>
                ))}
              </tbody>
            </Table>
          </Col>
          <Col md={3}>
            <News />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Collection;