import React, { useEffect, useState } from "react";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Badge from 'react-bootstrap/Badge';
import ListGroup from 'react-bootstrap/ListGroup';
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';

async function getData(formData) {
    console.log(process.env.REACT_APP_INFERENCE_API_URL);
    try {
        let formattedForm = formData;
        const result = await axios.get(process.env.REACT_APP_INFERENCE_API_URL+`/?${formattedForm}`)
        return result;
    } catch (error) {
        return error;
    }
}

function Valuation(props) {
    let urlParams = useParams();
    let [contractAddress, setContractAddress] = useState(urlParams?.contractAddress ? urlParams?.contractAddress : '0x60e4d786628fea6478f785a6d7e704777c86a7c6');
    let [ids, setIds] = useState('1,2,3,4,6,7');
    let [subcolFilter, setSubColFilter] = useState(null);
    let [results, setResults] = useState({});
    

    useEffect(()=>{
        let addressMatch = contractAddress.match(/(\b0x[a-f0-9]{40}\b)/g);
        if(addressMatch === null && contractAddress !== "") {
            setSubColFilter(contractAddress);
            setContractAddress("");
        }
    },[contractAddress]);

    const InferenceBlock = (props) => {
        return (
        <tr>
            <td><a href={`https://opensea.io/assets/${contractAddress}/${props.index}`} target="_blank" rel="noreferrer">{props.index}</a></td>
            <td>{props.data?.toFixed(2)} ETH</td>
        </tr>
        )
    }

    const handleSubmit = (e) => {
        console.log(`contract`, contractAddress, subcolFilter, ids);
        if((contractAddress === null || Object.keys(contractAddress).length === 0 || contractAddress === "") && subcolFilter === null) {
            console.log(`contract`, contractAddress);
            alert('Please enter a contract address or sub-collection filter.')
            e.preventDefault();
        } else {
            const formData = {
                contract_address: contractAddress,
                subcollection_name: subcolFilter,
                token_ids: ids
            }

            let addressMatch = contractAddress.match(/(\b0x[a-f0-9]{40}\b)/g)
            console.log(`matching address`,addressMatch);

            if(addressMatch !== null) {
                console.log('Collection Object is empty', contractAddress);
                delete formData.subcollection_name
                setSubColFilter(null);
            }

            if(subcolFilter !== null) {
                delete formData.contract_address
                setContractAddress(null);
            }

            console.log(`form data`, formData);

            const stringifiedFormData = new URLSearchParams(formData).toString();

            getData(stringifiedFormData).then((result)=>{
                setResults(result.data);
                console.log(result);
                
                if(result.statusCode === 200) {
                    e.target.reset();
                    setContractAddress(null);
                    setIds(null);
                    setSubColFilter(null);
                }
            }).catch((err) => {
                console.log(err);
            })
            e.preventDefault();
        }   
    }

    return (
        <>
        <Container className="text-start">
            <br/>
        <Row>
            <Col md={8}>
                <Row className="evalute">
                    <div className="h3" href="#">NFT Value Estimator <Badge bg="info">Alpha</Badge></div>
                </Row>
                <br/>
                <Row>
                    <Col>
                        <Form onSubmit={handleSubmit}>
                            <Row>
                                <Col>
                                    <Form.Control type="text" name="contract_address" value={contractAddress} onChange={e => setContractAddress(e.target.value)} placeholder="Contract Address" />
                                </Col>
                                <Col>
                                    <Form.Control type="text" name="subcollection_name" value={subcolFilter} onChange={e => setSubColFilter(e.target.value)} placeholder="Sub-Collection Filter" />
                                </Col>
                            </Row>
                            <br/>
                            <Form.Control type="text" name="token_ids" value={ids} onChange={e => setIds(e.target.value)} placeholder="Token IDs (e.g. 1,2,3,4,5,6,7...)" />
                            <br/>
                            <Button variant="outline-success" type="submit">Run</Button>
                        </Form>
                    </Col>
                </Row>
                <br/><br/>
                <Row>
                    <Col>
                        <Table className="summary">
                            <thead>
                            <tr>
                                <th>Token ID</th>
                                <th>Estimate</th>
                            </tr>
                            </thead>
                            <tbody>
                                { Object.keys(results).map((i)=>{
                                    return <InferenceBlock key={i} index={i} data={results[i]}></InferenceBlock>
                                })}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Col>
            <Col md={4}>
                <Row>
                    <div className="h5">Walkthrough</div>
                    <p>When working with the NFT Value Estimator, we support general collections that tie to a single smart contract, and special collections where one smart contract ties multiple projects underneath them.</p>
                    <p>For <i>Mutant Ape Yacht Club</i> for example, we would add to the Smart Contract Address field, the following address:<br/> <code>0x60e4d786628fea6478f785a6d7e704777c86a7c6</code></p>
                    <p>Meanwhile for <i>Emblem Vault</i>, we would use the Sub-Collection Filter with the supported names <code>Rare Pepes</code>, <code>Fidenza by Tyler Hobbs</code>, and <code>Spells of Genesis</code>.</p>
                    <p>For a collection to be valued, it must first be added to the "Collections". <Link to="/collection/create">Click here</Link> to a collection you are interested in if you don't see it in the drop down list. This process auto-backfills history, and kicks off the model generation process.</p>
                    <div className="h5">Issues / Improvements</div>
                    <ListGroup>
                        <ListGroup.Item className="grey"><Badge bg="info">In Progress</Badge>&nbsp;Currently collections with Fractionalized Items (e.g. Emblem Vault) will most likely give inaccurate valuations.</ListGroup.Item>
                        <ListGroup.Item className="grey"><Badge bg="secondary">Not Started</Badge>&nbsp;Currently cannot add Sub-Collections during Collection Creation</ListGroup.Item>
                        <ListGroup.Item className="grey"><Badge bg="secondary">Not Started</Badge>&nbsp;Select Collection via Dropdown(s)</ListGroup.Item>
                    </ListGroup>
                </Row>
            </Col>
        </Row>
        </Container>   
        </>
    )
}

export default Valuation