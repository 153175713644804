import axios from 'axios';
import { types } from '../types/types';

const token = localStorage.getItem('token') || "";

export const getBlacklistedNfts = () => {
  return async (dispatch) => {
    try {
      const nft = await axios.get(process.env.REACT_APP_API_URL + "/nfts/blacklistedNfts", {
        headers: {
          'x-token': token,
        },
      });
      if (nft.data.ok) {
        dispatch(blacklistedNfts(nft.data.nfts));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const createBlacklistedNft = (nft) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL + "/nfts/createAsBlacklisted", {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
        nft,
      });
      console.log(response);
      if (response.data.ok) {
        dispatch(blacklistedNfts(response.data.nfts));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const unblacklistNft = (hexId) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/nfts/unblacklist/${hexId}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': token,
        },
      });
      console.log(response);
      if (response.data.ok) {
        dispatch(blacklistedNfts(response.data.nfts));
      }
    } catch (error) {
      console.log(error);
    }
  };
};

const blacklistedNfts = (list) => ({
  type: types.NFT_GET_BLACKLIST,
  payload: list,
});

