import axios from 'axios';
import { types } from '../types/types';

const token = localStorage.getItem('token') || "";

export const fetchCollection = (id) => {
  return async (dispatch) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/collections/${id}`, {
        headers: {
          'x-token': token,
        }
      })
      dispatch(saveCollectionDetails(result?.data?.data));
      dispatch(loadIncrement());
    } catch (error) {
      return error;
    }
  };
}

export const getCollections = (page = 0, limit = 20) => {
  return async (dispatch) => {
    try {
      const collections = await axios.get(process.env.REACT_APP_API_URL + `/collections?page=` + page + `&limit=` + limit, {
        headers: {
          'x-token': token,
        }
      });

      if (collections.status === 200) {
        dispatch(saveCollections(collections.data.data));
      }
    } catch (error) {
      console.log(error);
    }
  };
};


export const fetchOlhc = (id, fromDate, toDate, groupBy = 'hour') => {
  return async (dispatch) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/collections/${id}/trades/ohlc?fromDate=${fromDate}&toDate=${toDate}&groupBy=${groupBy}`, {
        headers: {
          'x-token': token,
        }
      })
      if (result?.data?.data) {
        dispatch(saveOlhc(result.data.data));
      }
    } catch (error) {
      return error;
    }
  };
};

export const fetchAnalytics = (id) => {
  return async (dispatch) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/collections/${id}/analytics`, {
        headers: {
          'x-token': token,
        }
      });
      if (result?.data?.data[0]) {
        dispatch(saveAnalytics(result.data.data));
        dispatch(loadIncrement());
      }
      return result;
    } catch (error) {
      return error;
    }
  };
};

//Not being used yet
export const fetchCollectionNfts = (owner) => {
  return async (dispatch) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/nfts/collection/${owner}`, {
        headers: {
          'x-token': token,
        }
      })
      return result;
    } catch (error) {
      return error;
    }
  }
};

export const fetchTrades = (id, fromDate, toDate) => {
  return async (dispatch) => {
    try {
      const result = await axios.get(process.env.REACT_APP_API_URL + `/collections/${id}/trades?fromDate=${fromDate}&toDate=${toDate}`, {
        headers: {
          'x-token': token,
        }
      });
      if (result?.data?.data) {
        dispatch(saveTrades(result.data.data));
        dispatch(loadIncrement());
      }
      return result;
    } catch (error) {
      return error;
    }
  };
};

export const pushToFavorite = (collection) => {
  return async () => {
    let newFavState = !collection.favorite;
    await axios.put(process.env.REACT_APP_API_URL + `/collections/${collection.contract_address}`, { 'favorite': newFavState })
  };
};

const saveCollections = (collections) => ({
  type: types.COLLECTIONS_SAVE_COLLECTIONS,
  payload: collections,
});

const saveCollectionDetails = (collection) => ({
  type: types.COLLECTION_SAVE_COLLECTION_DETAILS,
  payload: collection,
});

const saveAnalytics = (analytics) => ({
  type: types.COLLECTION_SAVE_ANALYTICS,
  payload: analytics,
});

const saveTrades = (trades) => ({
  type: types.COLLECTION_SAVE_TRADES,
  payload: trades,
});

const saveOlhc = (olhc) => ({
  type: types.COLLECTION_SAVE_OLHC,
  payload: olhc,
});

const loadIncrement = () => ({
  type: types.COLLECTION_LOAD_INCREMENT,
});

