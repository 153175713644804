import React, {useState, useEffect, createContext} from "react";
import { auth } from "../services/firebase";
import { getAuth, signOut } from "firebase/auth";
export const UserContext = createContext({ user: null, loggedIn: false })

const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    
    useEffect(()=>{
        auth.onAuthStateChanged(async (user) => {
            console.log('state is changing')
            if(user !== null) {
                const {displayName, email} = user;
                setUser({ user: {displayName, email}, loggedIn: true});
            } else {
                setUser({ user: null, loggedIn: false });
            }
        });
    }, [])

    // Logout updates the user data to default
    const logout = () => {
        const auth = getAuth();
        signOut(auth).then(() => {
            setUser({ user: null, loggedIn: false });
        // Sign-out successful.
        }).catch((error) => {
        // An error happened.
        });
    };

    return (
        <UserContext.Provider value={{user, logout}}>{children}</UserContext.Provider>
    )
}

export default UserProvider;